import "./index.css";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
import honey from "./Honey.png";
import jetking from "./Jetking.png";
import mum from "./MUM.png";

const About = () => {
  const ImageScroller = () => (
    <div class="marquee">
      <div class="marquee__group">
        <img
          src="/tieupCompanies/AICFB.png"
          alt="AICFB"
          className="tieupCompaniesImages"
        />
        <img
          src="/tieupCompanies/bookmychef.png"
          alt="bookmychef"
          className="tieupCompaniesImages"
        />
        <img
          src="/tieupCompanies/BVC.png"
          alt="BVC"
          className="tieupCompaniesImages"
        />
        <img
          src="/tieupCompanies/galalite.png"
          alt="galalite"
          className="tieupCompaniesImages"
        />
        <img src={honey} alt="AICFB" className="tieupCompaniesImages" />
        <img
          src="/tieupCompanies/intercont.png"
          alt="intercont"
          className="tieupCompaniesImages"
        />
        <img src={jetking} alt="jetking" className="tieupCompaniesImages" />
        <img
          src="/tieupCompanies/MHS.png"
          alt="mhs"
          className="tieupCompaniesImages MHS"
        />
        <img src={mum} alt="mum" className="tieupCompaniesImages" />
        <img
          src="/tieupCompanies/Richkardz.png"
          alt="richkardz"
          className="tieupCompaniesImages"
        />
        <img
          src="/tieupCompanies/SA.png"
          alt="sa"
          className="tieupCompaniesImages"
        />
        <img
          src="/tieupCompanies/Sde.png"
          alt="sde"
          className="tieupCompaniesImages"
        />
        <img
          src="/tieupCompanies/SKS.png"
          alt="sks"
          className="tieupCompaniesImages"
        />
        <img
          src="/tieupCompanies/tea-culture-world.png"
          alt="teaculture"
          className="tieupCompaniesImages"
        />
      </div>
      <div aria-hidden="true" class="marquee__group">
        <img
          src="/tieupCompanies/AICFB.png"
          alt="AICFB"
          className="tieupCompaniesImages"
        />
        <img
          src="/tieupCompanies/bookmychef.png"
          alt="bookmychef"
          className="tieupCompaniesImages"
        />
        <img
          src="/tieupCompanies/BVC.png"
          alt="BVC"
          className="tieupCompaniesImages"
        />
        <img
          src="/tieupCompanies/galalite.png"
          alt="galalite"
          className="tieupCompaniesImages"
        />
        <img src={honey} alt="AICFB" className="tieupCompaniesImages" />
        <img
          src="/tieupCompanies/intercont.png"
          alt="intercont"
          className="tieupCompaniesImages"
        />
        <img src={jetking} alt="jetking" className="tieupCompaniesImages" />
        <img
          src="/tieupCompanies/MHS.png"
          alt="mhs"
          className="tieupCompaniesImages MHS"
        />
        <img src={mum} alt="mum" className="tieupCompaniesImages" />
        <img
          src="/tieupCompanies/Richkardz.png"
          alt="richkardz"
          className="tieupCompaniesImages"
        />
        <img
          src="/tieupCompanies/SA.png"
          alt="sa"
          className="tieupCompaniesImages"
        />
        <img
          src="/tieupCompanies/Sde.png"
          alt="sde"
          className="tieupCompaniesImages"
        />
        <img
          src="/tieupCompanies/SKS.png"
          alt="sks"
          className="tieupCompaniesImages"
        />
        <img
          src="/tieupCompanies/tea-culture-world.png"
          alt="teaculture"
          className="tieupCompaniesImages"
        />
      </div>
    </div>
  );
  const OurClientsScroller = () => (
    <div className="marque">
      <div className="marque__group">
        {[
          {
            src: "/clientImages/AnkitMehra.png",
            alt: "Ankita Mehra",
            className: "debashi",
          },
          {
            src: "/clientImages/debashi.png",
            alt: "Dr Debeshi Bhattacharjee",
            className: "debashi",
          },
          { src: "/clientImages/kiran.png", alt: "Dr Kiran Rukadikar" },
          { src: "/clientImages/madhu.png", alt: "Dr Madhu Chopra" },
          {
            src: "/clientImages/shikha.png",
            alt: "Shikha Khanna",
            className: "debashi",
          },
          { src: "/clientImages/shuti.jpeg", alt: "Dr Stuti Khare Shukla" },
          {
            src: "/clientImages/Tina.png",
            alt: "Tina Vinod",
            className: "debashi",
          },
          { src: "/clientImages/Trishna.png", alt: "Dr Trishna Gupte" },
          {
            src: "/clientImages/Twinkle.png",
            alt: "Twinkle Jain",
            className: "debashi",
          },
        ].map((client) => (
          <div className="clientImageWrapper" key={client.alt}>
            <img src={client.src} alt={client.alt} className="clientImages" />
            <span className={`clientName ${client.className || ""}`}>
              {client.alt}
            </span>
          </div>
        ))}
      </div>

      <div aria-hidden="true" className="marque__group">
        {[
          {
            src: "/clientImages/AnkitMehra.png",
            alt: "Ankita Mehra",
            className: "debashi",
          },
          {
            src: "/clientImages/debashi.png",
            alt: "Dr Debeshi Bhattacharjee",
            className: "debashi",
          },
          { src: "/clientImages/kiran.png", alt: "Dr Kiran Rukadikar" },
          { src: "/clientImages/madhu.png", alt: "Dr Madhu Chopra" },
          {
            src: "/clientImages/shikha.png",
            alt: "Shikha Khanna",
            className: "debashi",
          },
          { src: "/clientImages/shuti.jpeg", alt: "Dr Stuti Khare Shukla" },
          {
            src: "/clientImages/Tina.png",
            alt: "Tina Vinod",
            className: "debashi",
          },
          { src: "/clientImages/Trishna.png", alt: "Dr Trishna Gupte" },
          {
            src: "/clientImages/Twinkle.png",
            alt: "Twinkle Jain",
            className: "debashi",
          },
        ].map((client) => (
          <div className="clientImageWrapper" key={client.alt}>
            <img src={client.src} alt={client.alt} className="clientImages" />
            <span className={`clientName ${client.className || ""}`}>
              {client.alt}
            </span>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <div className="whatWeOffer">
        <div>
          {" "}
          <img
            src="/360_F_579485026_Fb2LO9GgmNKkOi18LjoBVAk1wUtZG2DE.jpg"
            alt="about us"
            className="aboutImg"
          />
        </div>

        <div className="aboutText">
          <p className="aboutDescript">
            MediaThings is a pioneering media services company committed to
            redefining how businesses communicate with their audiences.
            Combining innovative strategies with a client-focused approach,
            MediaThings offers a comprehensive suite of services designed to
            enhance brand visibility and audience engagement in today's dynamic
            media landscape. <br />
            <br />
            A team of seasoned media professionals with extensive industry
            experience, work together at MediaThings with a vision to deliver
            integrated media solutions. The company has become a trusted partner
            for businesses in various sectors like healthcare, entertainment,
            defence, logistics, FMCG etc, and seeking expert guidance in digital
            marketing, public relations, and media management. MediaThings
            excels through its dedication to quality, creativity, and
            results-driven strategies, ensuring every client achieves their
            communication objectives with precision and impact.
            <br />
            <br />
            With a dedication to quality, creativity, and results-driven
            strategies, MediaThings ensures that every client achieves their
            communication objectives with precision and impact. Whether you are
            looking to boost your digital presence, manage public relations, or
            track media impact, MediaThings is your trusted partner in
            navigating the complex media landscape.
          </p>
        </div>
      </div>

      <h2 className="heading">Our Clients</h2>
      <div className="scroller-container">
        <div className="body">{ImageScroller()}</div>
        <div className="body">{OurClientsScroller()}</div>
      </div>
    </>
  );
};

export default About;
